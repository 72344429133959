<template>
    <div class="app">
        <el-dialog
                title="发票录入"
                :visible.sync="dialogSendVisible"
                width="30%"
                :before-close="handleSendClose"
        >
            <el-timeline>
                <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item label="金额" prop="min"
                                  :rules="[{ required: true, message: '请填写金额', trigger: 'blur' },]">
                        <el-input-number :step="10" placeholder="请填写金额" v-model="form.min" @blur="input()" @change="input()"></el-input-number>
                    </el-form-item>
                    <el-form-item label="注：">
                        提现金额-（提交金额-发票金额）* 6% - 提现手续费
                    </el-form-item>
                    <el-form-item v-if="form.info" label="">
                        <font color="red">{{form.info}}</font>
                    </el-form-item>
                </el-form>

            </el-timeline>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleSendClose">取 消</el-button>
                <el-button type="primary" @click="confirm('form')">确 定</el-button>
              </span>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        components: {

        },
        props: {
            min:{
                type: Number,
                default: 0
            },
            id:{
                type: String,
                default: ''
            },
        },
        data() {
            return {
                date:'',
                form: {
                    min:18,
                    info:'',
                },
                dialogSendVisible: true,
            };
        },
        created() {
            this.form.min = this.min;
        },
        methods: {
            handleSendClose() {
                this.dialogSendVisible = false;
                this.$parent.tickets.show = false;
            },
            confirm(e) {
                console.log(this.id,this.form)
                if(!this.id || this.form.min < 0){
                    this.$message({
                        type: "error",
                        message: "参数错误!",
                    });
                    return false;
                }
                this.$refs[e].validate((res) => {
                    if (res) {

                        let url = this.$api.profitCashOrderUpdRealAmount;
                        let data = {
                            id:this.id,
                            invoice:this.form.min
                        };
                        this.$post(url, data).then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    type: "success",
                                    message: "成功!",
                                });

                                this.$parent.listData();
                                this.handleSendClose();
                            } else {
                                this.$message({
                                    type: "error",
                                    message: "失败!",
                                });
                            }
                        });
                    } else {
                        this.$message({
                            type: "warning",
                            message: "请填写完整信息！",
                        });
                        return false;
                    }
                });

            },
            input(){
                console.log(this.form.min,this.id);
                let url = this.$api.profitCashOrderCalculation;
                let data = {
                    id:this.id,
                    invoice:this.form.min
                };
                if(!this.id || !this.form.min || this.form.min < 0){
                    this.$message({
                        type: "error",
                        message: "参数错误!",
                    });
                    return false;
                }
                this.$post(url, data).then((res) => {
                    if (res.code === 1) {
                        this.form.info = res.data;
                    } else {
                        this.$message({
                            type: "error",
                            message: "失败!",
                        });
                    }
                });
            },
        },
    };
</script>
