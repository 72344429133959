<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>提现管理</el-breadcrumb-item>
                    <el-breadcrumb-item>提现申请</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>

        <div class="admin_main_block admin_m15">
            <div class="header-form">
                <div class="admin_main_block_left">
                    <el-form>
                        <el-form-item label="时间">
                            <el-date-picker
                                    style="width: 212px"
                                    v-model="date"
                                    @change="orderDate" @input="immediUpdate"
                                    type="daterange"
                                    range-separator="~"
                                    value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    start-placeholder="开始日期"
                                    end-placeholder="截止日期"
                            >
                                <i class="el-icon-date"></i>
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="角色">
                            <el-select v-model="where.accountOwnerRole" placeholder="请选择" clearable>
                                <el-option label="全部" value=""></el-option>
                                <el-option label="运营中心" value="OPERATION_CENTER"></el-option>
                                <el-option label="服务商" value="SERVICE_PROVIDER"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="对公/对私">
                            <el-select v-model="where.bankAccountType" placeholder="请选择" clearable>
                                <el-option label="全部" value=""></el-option>
                                <el-option label="对公" value="OPEN"></el-option>
                                <el-option label="对私" value="INDIVIDUAL"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" plain icon="el-icon-search" @click="listData()">条件筛选</el-button>
                        </el-form-item>
                    </el-form>
                </div>

                <div class="admin_main_block_right">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-button type="primary" icon="el-icon-more" @click="objFltrate.show = !objFltrate.show">
                                字段
                            </el-button>
                        </el-col>
                        <el-col :span="12">
                            <el-button
                                    type="primary"
                                    @click="exportData"
                                    :loading="download"
                                    disabled
                                    icon="el-icon-tickets">导出数据
                            </el-button>
                        </el-col>
                    </el-row>
                </div>
            </div>

            <div class="admin_table_main">
                <el-table
                        :data="list"
                        v-loading="loading"
                        element-loading-text="拼命加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.1)"
                        @selection-change="handleSelectionChange"
                >
                    <div v-for="items in objFltrate.itemsOptions" :key="items.value" :show-overflow-tooltip="true">
                        <el-table-column :prop="items.value" v-if="objFltrate.checkedCities.includes(items.value)"
                                         :label="items.name"></el-table-column>
                    </div>
                    <el-table-column label="操作" fixed="right" width="200px">
                        <template slot-scope="scope">
                            <el-button type="primary" class="el-icon-document-checked" @click="deleteBtn(scope.row.id)">标记打款</el-button>
                            <el-button type="success" class="el-icon-tickets" @click="ticketsBtn(scope.row.id)">发票</el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <div class="admin_table_main_pagination">
                    <el-pagination
                            @current-change="currentChange"
                            @size-change="handleSizeChange"
                            background
                            :total="totalData"
                            :page-size="pageSize"
                            :current-page="currentPage"
                            :page-sizes="[10, 20, 50, 100]"
                            layout="sizes, prev, pager, next, total, jumper"
                    ></el-pagination>
                </div>
            </div>
        </div>
        <flteate v-if="objFltrate.show" :objFltrate="objFltrate"></flteate>
        <tickets v-if="tickets.show" :id="tickets.id"></tickets>
    </div>
</template>

<script>
    import {exportFile} from "@/plugins/function.js";
    import flteate from "@/components/admin/common/filtrate.vue";
    import tickets from "@/components/admin/withdrawal/apply/tickets.vue";

    export default {
        components: {
            flteate,
            tickets,
        },
        props: {
            state: Number,
        },
        data() {
            return {
                span: 6,
                span4: 5,
                span12: 12,
                span10: 10,
                list: [],
                date: "",
                row: {},
                loading: false,
                dialogDetailVisible: false,
                dialogEditVisible: false,
                totalData: 0, // 总条数
                pageSize: 20,
                currentPage: 0,
                rolesData: [],
                selectId: "",
                where: {
                    pageNum: "",//分页，页数
                    pageSize: "",//分页，每页大小
                    startDt: "",//开始日期【yyyy-MM-dd】
                    endDt: "",//结束日期【yyyy-MM-dd】
                    accountOwnerRole: "",// 角色【OPERATION_CENTER-运营中心  SERVICE_PROVIDER-服务商】
                    bankAccountType: "",// 对公/对私【OPEN-对公  INDIVIDUAL-对私】
                },
                total: {
                    count: 0,  // 总笔数
                    cashAmount: 0, // 总提现金额
                    cashFee: 0, // 总提现手续费
                    realAmount: 0, // 总到账金额
                },
                download: false,
                objFltrate: {
                    show: false,
                    localStorage: 'fltrate_withdrawal_apply',
                    checkedCities: [
                        "id",
                        "cashAmount",
                        "cashFee",
                        "realAmount",
                        "createTime",
                        "cashTaxFee",
                        "accountOwnerRoleText",
                        "accountOwnerName",
                        "bankAccountNo",
                        "bankOpenbankname",
                        "bankAccountName",
                        "bankAccountTypeText",
                    ],
                    itemsOptions: [
                        {value: "id", name: "提现申请ID"},
                        {value: "cashAmount", name: "提现金额"},
                        {value: "cashFee", name: "提现手续费"},
                        {value: "realAmount", name: "到账金额"},
                        {value: "createTime", name: "创建时间"},
                        {value: "cashTaxFee", name: "提现税费"},
                        {value: "actualInvoice", name: "发票"},
                        {value: "accountOwnerRoleText", name: "拥有者角色"},
                        {value: "accountOwnerName", name: "拥有者名称"},
                        {value: "bankAccountNo", name: "收款账号（银行卡号）"},
                        {value: "bankOpenbankname", name: "收款开户行"},
                        {value: "bankAccountName", name: "收款户名（持卡人姓名）"},
                        {value: "bankAccountTypeText", name: "对公/对私"},
                    ],
                },
                tickets:{
                    show:false,
                    id:0,
                },
            };
        },

        methods: {
            listData() {
                this.loading = true;
                this.where.pageNum = this.currentPage;
                this.where.pageSize = this.pageSize;
                this.$post(this.$api.getWithdrawApplyList, this.where).then((res) => {
                    this.totalData = res.data.total;
                    this.list = res.data.records;
                    this.loading = false;
                });
            },
            handleSelectionChange(e) {
                let ids = [];
                e.forEach((v) => {
                    ids.push(v.id);
                });
                this.selectId = ids.join(",");
            },
            currentChange(e) {
                this.currentPage = e;
                this.listData();
            },
            handleSizeChange(e) {
                this.pageSize = e;
                this.listData();
            },
            orderDate(obj) {
                if (obj) {
                    this.where.startDt = obj[0];
                    this.where.endDt = obj[1];
                }
            },
            immediUpdate(e) {
                this.$nextTick(() => {
                    console.log(e)
                    if(e==null){
                        this.where.startDt = '';
                        this.where.endDt = '';
                    }
                });
            },
            exportData() {
                // 提现列表导出Excel
                this.download = true;
                this.$post(this.$api.profitCashOrderListExport, {...this.where, export: true}, true).then((res) => {
                    exportFile(res.data, decodeURI(res.headers.filename));
                    this.download = false;
                });
            },
            deleteBtn(id) {
                this.$confirm("是否标记打款?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        const url = this.$api.markUpPlayMoney + id;
                        this.get(url, this.form);
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消",
                        });
                    });
            },
            ticketsBtn(id){
                console.log(id);
                this.tickets.show = true;
                this.tickets.id = id;
            },
            get(url, data) {
                let that = this;
                this.$get(url, data).then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            type: "success",
                            message: "成功!",
                        });
                        that.listData();
                    } else {
                        this.$message({
                            type: "error",
                            message: "失败!",
                        });
                    }
                });
            },
            getFltrate() {
                let obj = localStorage.getItem(this.objFltrate.localStorage);
                if (obj) {
                    this.objFltrate.checkedCities = JSON.parse(obj);
                }
            },
        },

        created() {
            this.listData();
            this.getFltrate();
        },
    };
</script>
<style lang="scss" scoped>
    .total-header {
        margin-bottom: 0px !important;
        margin-top: 15px;

        .el-col {
            margin-right: 15px;

            :last-child {
                margin-right: 0px;
            }
        }
    }

    .el-row {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .el-col {
        border-radius: 4px;
    }

    .header-form {
        height: 40px;
        padding: 10px 0 10px 0;
    }

    .span4 {
        background: rgb(217, 236, 255);
        font-size: 16px;
        padding: 30px 0;
        text-align: center;
        vertical-align: center;

        span {
            font-size: 18px;
        }
    }

    /deep/ .el-input-number.is-controls-right .el-input__inner {
        padding-left: 2px;
        padding-right: 32px;
    }

    .endDate {
        color: #c0c4cc;
    }

    .el-image {
        height: 100px;
        width: 100px;
    }

    .el-select {
        width: 120px;
    }

    .status1, .daifuStatusY, .creditStatusY, .rollbackStatusY {
        color: #67C23A;
    }

    .status2, .daifuStatusU, .creditStatusU, .rollbackStatusU {
        color: #E6A23C;
    }

    .status3, .daifuStatusN, .creditStatusN, .rollbackStatus {
        color: #F56C6C;
    }

    .status4, {
        color: #909399;
    }

    /*.OPERATION_CENTER,.AD_PAY{
        color: #409EFF;
    }
    .OPERATION_CENTER,.AD_PAY{
        color: #a84a26;
    }*/
</style>
